import _numberIsNan from "number-is-nan";
var exports = {};
var numberIsNan = _numberIsNan;

exports = function (x) {
  if (numberIsNan(x)) {
    return false;
  } // https://github.com/nodejs/io.js/blob/cff7300a578be1b10001f2d967aaedc88aee6402/lib/readline.js#L1369
  // code points are derived from:
  // http://www.unix.org/Public/UNIDATA/EastAsianWidth.txt


  if (x >= 4352 && (x <= 4447 || // Hangul Jamo
  9001 === x || // LEFT-POINTING ANGLE BRACKET
  9002 === x || // RIGHT-POINTING ANGLE BRACKET
  // CJK Radicals Supplement .. Enclosed CJK Letters and Months
  11904 <= x && x <= 12871 && x !== 12351 || // Enclosed CJK Letters and Months .. CJK Unified Ideographs Extension A
  12880 <= x && x <= 19903 || // CJK Unified Ideographs .. Yi Radicals
  19968 <= x && x <= 42182 || // Hangul Jamo Extended-A
  43360 <= x && x <= 43388 || // Hangul Syllables
  44032 <= x && x <= 55203 || // CJK Compatibility Ideographs
  63744 <= x && x <= 64255 || // Vertical Forms
  65040 <= x && x <= 65049 || // CJK Compatibility Forms .. Small Form Variants
  65072 <= x && x <= 65131 || // Halfwidth and Fullwidth Forms
  65281 <= x && x <= 65376 || 65504 <= x && x <= 65510 || // Kana Supplement
  110592 <= x && x <= 110593 || // Enclosed Ideographic Supplement
  127488 <= x && x <= 127569 || // CJK Unified Ideographs Extension B .. Tertiary Ideographic Plane
  131072 <= x && x <= 262141)) {
    return true;
  }

  return false;
};

export default exports;